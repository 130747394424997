@media only screen and (max-width: 760px) and (max-height: 668px) {
  .flex-row {
    display: flex;
  }

  .flex-row .form-group {
    margin: 0 !important;
  }

  .flex-row .form-group:first-child {
    margin-right: 5px !important;
  }

  .flex-row .form-group:last-child {
    margin-left: 5px !important;
  }
}

@media only screen and (max-width: 760px) and (max-height: 570px) {
  .main {
    margin-top: 3em !important;
  }

  .main .container {
    padding: 40px 10px !important;
  }
}

@media only screen and (max-width: 760px) {
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main .container {
    padding: 40px 30px;
    width: 100%;
  }

  .button {
    text-decoration: none;
    width: 100%;
    border: 0;
    background: #0063cf;
    height: 45px;
    color: #fff;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background: #fff;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.11);
  padding: 45px 70px;
  width: 20rem;
  min-height: 35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container > div {
  width: 100%;
}
p,
h5,
.p,
.h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: normal;
  line-height: 1.5;
  color: inherit;
}

.form-group input {
  width: 300px;
  border: 0;
  border-bottom: 1px solid #0063cf;
  padding: 5px 0;
  color: #003f72;
  font-size: 16px;
  margin: 10px 0;
}

.form-group input:focus,
.form-group input:not(:focus) {
  outline: none;
  font-size: 16px;
  padding: 10px 0;
}

.form-group input::-webkit-input-placeholder {
  /* Edge */
  color: #455a64;
}

.form-group input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #455a64;
}

.form-group input::placeholder {
  color: #455a64;
}

.form-group span {
  font-family: "Roboto Bold";
}

.button {
  width: 100%;
  border: 0;
  border-radius: 4px;
  background: #0063cf;
  height: 45px;
  color: #fff;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  margin-top: 30px;
}

.button:hover {
  background: #003e83;
  color: #fff;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 29px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkbox-container:hover input ~ .checkbox-checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkbox-checkmark {
  background-color: #0063cf;
}

.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkbox-checkmark:after {
  display: block;
}

.checkbox-container .checkbox-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.box-field {
  background: #ffffff;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 0 3px 0;
  min-height: 56px;
  width: 100%;
}
