body {
  overflow-x: hidden;
}

#logo {
  height: 38px;
  transform: scale(0.85);
}

.consultation-info-header {
  display: none;
}

.container-pay-row {
  position: relative;
  margin: 0;
}

#header {
  position: relative;
  background-color: #0064cf;
  box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
  padding: 35px 10px;
  width: 100%;
}

#header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header-row {
  width: 100%;
  margin: 0;
}

#divider {
  height: 40px;
  transform: scale(1.15);
}

#link {
  content: url("../../images/link-de-pagamento.svg");
}

.btn-outline {
  padding: 8px 16px;
  height: 32px;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #ffffff;
  margin-right: 16px;
}

.btn-outline:hover {
  border: 1px solid #a0cff8;
  color: #a0cff8;
  background-color: transparent;
}

.btn-login {
  padding: 8px 16px;
  height: 32px;
  border: 1px solid #d9ebfc;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.3px;
  background: #d9ebfc;
  color: #607d8b;
  display: inline-flex;
  justify-content: center;
}

.btn-login:hover {
  border: 1px solid #a0cff8;
  background: #a0cff8;
  color: #607d8b;
}

#buttons {
  float: right;
}

#area-name {
  margin-bottom: 8px;
}

#area-description {
  margin-bottom: 45px;
}

#card-border {
  max-width: 555px;
  height: 4px;

  margin-top: 40px;
  background: #228bf0;
  border-radius: 4px 4px 0px 0px;
}

#card-right {
  margin-top: 40px;
}

#card-form {
  padding: 32px;
  width: 464px;
  max-width: 555px;
  background: #ffffff;
  box-shadow:
    0px 4px 14px rgba(0, 99, 207, 0.06),
    0px 6px 16px rgba(0, 99, 207, 0.02),
    0px 6px 24px rgba(0, 99, 207, 0.04);
  border-radius: 4px;
  height: 538px;
  max-height: 538px;

  border-radius: 4px 4px 0px 0px;
  border-top: 4px solid #228bf0;
  margin-top: 40px;
}

.container-pay {
  min-width: 100vw;
  min-height: 100vh;
  background-image: url("../../images/logo-background-10.svg");
  background-color: #0063cf;
  background-position: right center;
  background-repeat: no-repeat;
}

.form-control {
  border: none;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.444444px;
}

.form-control-lg {
  padding: 0.375rem 0.75rem;
}

::-webkit-input-placeholder {
  color: #90a4ae;
}

:-ms-input-placeholder {
  color: #90a4ae;
}

::placeholder {
  color: #90a4ae;
}

.form-control {
  height: 30px;
  font-size: 18px;
}

.form-select {
  border: 0 !important;
  font-size: 15px;
}

.btn-lg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  max-width: 248px;
  height: 56px;
  left: 768px;
  top: 526px;
  background: #0063cf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #f8f9fa;
  float: right;
}

.btn-lg:hover {
  background-color: #0054af;
  color: #ffffff;
}

.center {
  display: grid;
  place-items: center;
}

.form-container {
  position: relative;
  margin-top: 150px;
  gap: 30px;
}

.item-3 {
  margin-bottom: 200px;
}

@media (max-width: 767px) {
  #link {
    content: url("../../images/link-de-pagamento-mini.svg");
    transform: scale(0.8);
  }

  #card-info {
    margin-top: 162px;
  }

  .info-side {
    display: none;
  }

  .info-bottom {
    display: block;
    margin-top: 30px;
  }

  #card-border {
    display: block;
    max-width: 100vw;
  }

  #card-form {
    display: block;
    max-width: 100vw;
    max-height: 100%;
    height: 100%;
  }

  .footer-small {
    margin-top: 0;
    padding: 30px;
  }

  .footer-big {
    display: none;
  }

  .flex {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  #header {
    background-color: transparent;
    box-shadow: none;
  }

  #header-content {
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
  }

  .form-container {
    margin-top: 20px;
    max-width: 1200px;
    width: 95%;
    gap: 30px 5px;
    display: grid;
    grid-template-columns: 1fr 0.45fr 1fr;
    padding: 0px 0px;
  }

  .consultation-info-header {
    width: 95%;
    max-width: 1200px;
    padding: 0px 12px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .consultation-info-header-text {
    font-size: 20px;
    font-weight: 700;
    color: white;
  }

  .item-1 {
    grid-column-start: 0;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .item-2 {
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 10;
    max-height: 700px;
    height: 700px;
    padding: 0;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }

  .card-form-content {
    flex: 1;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 9px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0063cf;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #0054af;
  }

  .item-3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 10;
    height: 100%;
    margin-bottom: 0px;
  }
}

@media (min-width: 1240px) {
  .form-container {
    width: 100%;
  }

  .consultation-info-header-text {
    font-size: 24px;
    font-weight: 700;
    color: white;
  }
}
