.container {
  background: #fff;
  width: 72% !important;
  height: calc(100vh - 156px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #455A64;
  margin-top: 27px;
}

.sub-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #607D8B;
  margin-top: 8px;
}