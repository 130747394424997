@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'), url(fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'), url(fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url(fonts/Roboto-Mono/RobotoMono-Regular.ttf) format('truetype');
}

:root {
  --medicinae-orange: #FF6A13;
  --medicinae-dark-blue: #1B365D;
  --medicinae-light-blue: #5B7F95;
}


body {
  font-family: 'Roboto', sans-serif !important;
  display: flex;
  margin: 0;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  padding-right: 5px;
}

.m-0-auto {
  margin: 0 auto !important;
}

.mt-24 {
  margin-top: 24px !important;
}
